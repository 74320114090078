import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Parablack,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify={"space-between"} align={"top"} wrap={true}>
          {/* <Row justify="center" align="top"> */}
            {/* <Col lg={10} md={10} sm={12} xs={12}> */}
            <Col xs={24} sm={20} md={16} lg={12} xl={8}>
              <Language>{t("Get In Touch")}</Language>
              {/* <Large to="/">{t("(905) 574-2222")}</Large> */}

              <a href="tel:9055742222" target="_blank" rel="noreferrer">
                <Para>{t("(905) 574-2222")}</Para>
              </a>
              <br></br>
         

              <a href="mailto:info@stonehilldental.ca" target="_blank" rel="noreferrer">
                <Para>{t("info@stonehilldental.ca")}</Para>
              </a>
              <br></br>
       
              <a href="https://www.stonehilldental.ca/contact-us/" target="_blank" rel="noreferrer">
                <Para>{t(`View Contact Page`)}</Para>
              </a>
              <br></br>

              <a href="https://www.stonehilldental.ca/" target="_blank" rel="noreferrer">
                <Para>{t(`StonehillDental.ca`)}</Para>
              </a>
              <br></br>

              <Parablack>Mon-Thurs: 8am-5:30pm</Parablack>
              <Parablack>Fri: 8am-3pm</Parablack>
              <Parablack>Sat: 8am-2pm</Parablack>
           
           
            </Col>
            {/* <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("All On 4")}</Title>
              <Large to="https://www.stonehilldental.ca/dental-services/cosmetic-dentistry/teeth-in-a-day/#contact-us" left="true">
                {t("Our Comments")}
              </Large>
              <Large left="true" to="/">
                {t("Medical Study")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t("Dental Implants")}
              </Large>
              <Large left="true" to="/">
                {t("All On 4 Video")}
              </Large>
            </Col> */}
            <Col xs={24} sm={20} md={16} lg={12} xl={8}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Parablack>1314 Upper Wentworth St</Parablack>
              <Parablack>Hamilton, ON L9A 4W2</Parablack>
              <Parablack>Canada</Parablack>
              <br></br>
              <a href="https://goo.gl/maps/SHW3hpQ5vUdyxHvM9" target="_blank">
                <Para>{t(`View on Google Maps`)}</Para>
              </a>
            </Col>

            <Col xs={24} sm={20} md={16} lg={12} xl={8}>
              <Title>{t("All On 4")}</Title>

              <a href="https://www.stonehilldental.ca/dental-services/cosmetic-dentistry/teeth-in-a-day/#contact-us" target="_blank" rel="noreferrer">
                <Para>{t("Our Comments")}</Para>
              </a>
              <br></br>
         

              <a href="https://meridian.allenpress.com/joi/article/38/3/251/7147/Posttreatment-Quantification-of-Patient" target="_blank" rel="noreferrer">
                <Para>{t("Medical Study")}</Para>
              </a>
              <br></br>
       
              <a href="https://www.stonehilldental.ca/dental-services/restorative-dentistry/dental-implants/" target="_blank" rel="noreferrer">
                <Para>{t(`Dental Implants`)}</Para>
              </a>
              <br></br>

              <a href="https://www.stonehilldental.ca/wp-content/uploads/2020/06/teeth_in_a_day.mp4" target="_blank" rel="noreferrer">
                <Para>{t(`All On 4 Video`)}</Para>
              </a>
              <br></br>
              {/* <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large> */}
            </Col>
          {/* </Row> */}
          </Row>
          {/* <Row justify="space-between"> */}
            
            
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> */}
          {/* </Row> */}
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="stonehill_logo_v1.webp"
                  aria-label="homepage"
                  width="265px"
                  height="89.6px"
                />
              </LogoContainer>
            </NavLink>
            {/* <FooterContainer>
              <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              />
              <a href="https://www.buymeacoffee.com/adrinlol">
                <img
                  src="https://img.buymeacoffee.com/button-api/?text=Buy me a pizza&emoji=🍕&slug=adrinlol&button_colour=FF5F5F&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"
                  alt="Buy me a pizza"
                />
              </a>
             
            </FooterContainer> */}
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
